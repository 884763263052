import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import img from "../../../../assets/images/login.png";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import "dayjs/locale/en-gb";
import CustomSelect from "../../../Common/CustomSelect";
import { Card, CardBody, InputTitle, InputWrapper } from "../Header/style";

import { Text } from "../../../../globalStyle";
// import LCApplicationComp from "./ApplicationComp";
import CustomButton from "../../../Common/CustomButton";
import {
  getPCContact,
  getPCContactById,
} from "../../../../store/actions/PCContactActions";
import useSearch from "../../../../hooks/useSearch";
import { useLocation, useNavigate } from "react-router-dom";
import UseReplace from "../../../../hooks/useReplace";
import {
  createPCApplicationThunk,
  createPCApplicationWithClientThunk,
  deletePCContactsThunk,
  editPCApplicationThunk,
} from "../../../../store/actions/PCApplicationsActions";
import { LCAppType, LCClientDataT, onChangeAppType } from "../../types";
// import BottomListItem from "./BottomListItem";
import ClientCard from "./ClientCard";
import SearchClientCard from "./SearchClientCard";
import { defaultLCClientData, defaultListItem } from "../../data";
import {
  LCAppsDataT,
  LCDataType,
  LCOnDropT,
  appT,
  PCAppsDataT,
  PCOnDropT,
  selectT,
  statusT,
} from "../../../../types/commonTypes";
import { ContentCopyOutlined, ContentPasteOutlined } from "@mui/icons-material";
import {
  FlexBetween,
  FlexCenter,
  FlexWrapper,
} from "../../../Common/FlexWrapper";
import {
  setAppsByStepPending,
  setAppsData,
  setCopiedApp,
} from "../../../../store/slices/LCApplicationsSlice";
import { openSnackbar } from "../../../../store/slices/mainSlice";
import {
  ChooseRecommended,
  SearchInputWrapper,
  WrapperRecommended,
} from "./style";
import CommonModal from "../../../Common/CustomModal";
import { getTypes } from "../../../../store/actions/settingsActions";
import CustomAutocomplete from "../../../Common/CustomAutocomplete";
import { getEmployeesList } from "../../../../store/actions/employeesActions";
import {
  createLCApplicationThunk,
  createLCApplicationWithClientThunk,
  editLCApplicationThunk,
  getLCApplicationsByStep,
} from "../../../../store/actions/LCApplicationsActions";
import CustomInput from "../../../Common/CustomInput";
import { getLCContact } from "../../../../store/actions/LCContactActions";
import { convertDateFormat, prettierNum } from "../../../../utils/helpers";
import i18next from "i18next";
import styled from "styled-components";
import edit from "../../../../assets/icons/settings/dark-edit.svg";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "../../../Common/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import CustomInputTextfield from "../../../Common/CustomInputTextfield";
import { useTranslation } from "react-i18next";
const AddLCApplication: React.FC<{
  handleClose: () => void;
  isFillPricing?: boolean;
  onDrop?: (data: LCOnDropT) => void;
}> = ({ handleClose, isFillPricing, onDrop }) => {
  const { data: contacts, pending } = useAppSelector(
    (state) => state.LCContact
  );
  const {
    actionsPending,
    editingApp,
    copiedApp,
    fillPricingData,
    cannotEditApp,
    exceptedNotification,
  } = useAppSelector((state) => state.LCApplications);
  const { dataList: employees } = useAppSelector((state) => state.employees);
  const { t } = useTranslation();
  const { Data, profile } = useAppSelector((state) => state.settings);
  const { about, lsStatuses, ourCommitments } = Data;
  const [isAddClicked, setIsAddClicked] = useState(false);
  const dispatch = useAppDispatch();
  const appTypes = t("LCApplications.applicationsTypes", {
    returnObjects: true,
  }) as statusT[];
  const [clientData, setClientData] =
    useState<LCClientDataT>(defaultLCClientData);
  const [recommendedData, setRecommendedData] = useState<
    { id: number; name: string }[]
  >([]);
  const [removedContacts, setRemovedContacts] = useState<any[]>();
  const [applicationData, setApplicationData] = useState<LCAppType>({
    client: null,
    status: null,
    note: null,
    manager: null,
    about: null,
    price: null,
    started_at: null,
  });
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const handleInputChange = (value: string) => {
    dispatch(getEmployeesList({ name: value }));
    setInputValue(value);
    setAutoCompleteOptions(employees.results);
  };
  const [openRecommendedModal, setOpenRecommendedModal] = useState(false);
  const handleOpenRecommendedModal = () => {
    setOpenRecommendedModal(true);
  };
  const handleCloseRecommendedModal = () => {
    setOpenRecommendedModal(false);
  };
  const [list, setList] = useState<appT[]>([]);
  const [designerData, setDesignerData] = useState<any>({
    type_of_designs: [],
    designers: [],
    designer_price: 0,
  });
  const [listItem, setListItem] = useState<appT>(defaultListItem);
  const [manager, setManager] = useState("");
  const [editingAppComp, setEditingAppComp] = useState<null | number>(null);

  const [selectedContact, setSelectedContact] = useState<LCDataType | null>(
    null
  );
  const [selectedContactRecommended, setSelectedContactRecommended] = useState<
    number[]
  >([]);
  const [checkErrors, setCheckErrors] = useState(false);

  console.log(editingApp);

  const query = useSearch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (employees.results) {
      setAutoCompleteOptions(
        employees.results.map((value) => ({
          id: Number(value?.id),
          name: value?.first_name + " " + value?.last_name,
        }))
      );
    }
  }, [employees, dispatch]);
  useEffect(() => {
    applicationData.manager?.first_name
      ? setInputValue(
          applicationData.manager?.first_name +
            " " +
            applicationData.manager?.last_name
        )
      : // @ts-ignore
        setInputValue(applicationData.manager?.name);
  }, [applicationData.manager]);
  useEffect(() => {
    dispatch(getTypes({ url: "legal-settings", dataType: "lsStatuses" }));
    dispatch(getTypes({ url: "job-commitments", dataType: "ourCommitments" }));
    dispatch(getTypes({ url: "type-design", dataType: "typeServices" }));
  }, []);
  useEffect(() => {
    dispatch(getTypes({ url: "legal/interest", dataType: "lcInterest" }));
    dispatch(getEmployeesList({}));
  }, [dispatch]);
  // useEffect(() => {
  //   if (exceptedNotification) {
  //     const { activity, full_name, work, telegram, client, name_type } =
  //       exceptedNotification;

  //     setApplicationData({ ...applicationData, work });
  //     if (client) dispatch(getPCContactById(client!));
  //     else
  //       setClientData({
  //         ...clientData,
  //         activity,
  //         name_type,
  //         full_name,
  //         telegram,
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [exceptedNotification]);
  const [checkedJobTypes, setCheckedJobItems] = useState<number[]>([]);
  const [doneJobTypes, setDoneJobItems] = useState<number[]>([]);
  const [isEditJobItems, setIsEditJobItems] = useState<boolean>(false);

  const handleCheckboxChange = (itemID: number) => {
    if (checkedJobTypes.includes(itemID)) {
      // If item is already checked, remove it
      setCheckedJobItems(checkedJobTypes.filter((item) => item !== itemID));
    } else {
      // If item is not checked, add it
      setCheckedJobItems([...checkedJobTypes, itemID]);
    }
  };
  const handleDoneCheckboxChange = (itemID: number) => {
    if (doneJobTypes.includes(itemID)) {
      // If item is already checked, remove it
      setDoneJobItems(doneJobTypes.filter((item) => item !== itemID));
    } else {
      // If item is not checked, add it
      setDoneJobItems([...doneJobTypes, itemID]);
    }
  };
  // useEffect(() => {
  //   if (contactById?.id) {
  //     const { company_name, activity } = contactById;
  //     setClientData({
  //       ...clientData,
  //       activity,
  //       company_name,
  //     });

  //     navigate(`${pathname}${UseReplace("search-client-name", company_name)}`);
  //     setSelectedContact(contactById);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [contactById]);
  const [finalValue, setFinalValue] = useState<string>("");

  useEffect(() => {
    const delay = 500; // Adjust the delay as needed (e.g., 500 milliseconds)
    const timeoutId = setTimeout(() => {
      setFinalValue(clientData.company_name);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [clientData.company_name]);
  useEffect(() => {
    navigate(
      `${pathname}${UseReplace("search-client-name", String(finalValue))}`
    );
  }, [finalValue]);
  const onChange = (value: string | number | any[], name: string) => {
    setClientData({
      ...clientData,
      [name === "company_name" ? "company_name" : name]:
        name === "company_name" ? String(value) : value,
    });
  };
  const [nameExistAppear, setNameExistAppear] = useState(false);
  useEffect(() => {
    if (!pending) {
      if (
        (selectedContact?.company_name.toLowerCase() ===
          clientData.company_name.toLowerCase() ||
          editingApp?.client?.company_name.toLowerCase() ===
            clientData.company_name.toLowerCase()) &&
        (clientData.contacts[0]?.telegram
          ? selectedContact?.contacts[0].telegram.toLowerCase() ===
              clientData.contacts[0].telegram.toLowerCase() ||
            editingApp?.client?.contacts[0].telegram.toLowerCase() ===
              clientData.contacts[0].telegram.toLowerCase()
          : selectedContact?.contacts[0]?.phone_number ===
              clientData.contacts[0]?.phone_number ||
            editingApp?.client?.contacts[0]?.phone_number ===
              clientData.contacts[0]?.phone_number)
      ) {
        setNameExistAppear(false);
      } else {
        if (
          !!!contacts?.results?.find(
            (contact) =>
              contact.company_name.toLowerCase() ===
                clientData.company_name.toLowerCase() &&
              (clientData.contacts[0]?.telegram
                ? contact.contacts[0].telegram.toLowerCase() ===
                  clientData.contacts[0].telegram.toLowerCase()
                : contact.contacts[0]?.phone_number.toLowerCase() ===
                  clientData.contacts[0]?.phone_number.toLowerCase())
          )
        ) {
          setNameExistAppear(false);
        }
        if (
          !!contacts?.results?.find(
            (contact) =>
              contact.company_name.toLowerCase() ===
                clientData.company_name.toLowerCase() &&
              (clientData.contacts[0]?.telegram
                ? contact.contacts[0].telegram.toLowerCase() ===
                  clientData.contacts[0].telegram.toLowerCase()
                : contact.contacts[0]?.phone_number.toLowerCase() ===
                  clientData.contacts[0]?.phone_number.toLowerCase())
          )
        ) {
          setNameExistAppear(true);
        }
      }
    }
  }, [contacts.results, pending, clientData, selectedContact?.id]);

  console.log(editingApp, "editingapplog");
  const onChangeSelect = (
    e: SelectChangeEvent,
    name: string,
    data: selectT[]
  ) => {
    console.log(data, e.target.value, "datae.target.value");

    const selectData = data;
    !e.target.value && setClientData(defaultLCClientData);
    setClientData({
      ...clientData,
      [name]: selectData.find((item) => item.id === Number(e.target.value))!,
    });
  };
  const onChangeSelectAutocomplete = (e: any, name: string) => {
    !e && setClientData(defaultLCClientData);
    setClientData({
      ...clientData,
      [name]: e!,
    });
  };
  const onChangeSelectApplication = (
    e: string,
    name: string,
    dataItem: selectT[]
  ) => {
    const selectData = dataItem;

    setApplicationData({
      ...applicationData,
      [name]: selectData.find((item) => item.id === Number(e))!,
    });
  };

  const selectContact = (id: number) => {
    const contact = contacts.results.find((item) => item.id === id);
    setSelectedContact(contact!);
    setClientData(defaultLCClientData);
    console.log("nima gaolade");
  };
  // const selectContactRecommended = (id: number) => {
  //   const contact = contacts.results.find((item) => item.id === id);
  //   setSelectedContactRecommended(contact!);
  //   setRecommendedData(defaultClientData);
  // };
  const changeObject = (listData?: appT[]) => {
    const customList = listData ? listData : list;
    console.log(listItem);

    if (listItem.job?.id) customList.push(listItem);
    return customList.map(({ job, number, price, work, sum }) => ({
      work_id: +work?.id!,
      job_id: +job?.id!,
      price: +price / +number || 0,
      price2: (+sum - +price) / +number || 0,
      number: +number,
    }));
  };
  console.log(applicationData, "salomlarmi");
  const clientSend = {
    company_name: clientData.company_name.trim(),
    manager: clientData.manager?.id || undefined,
    activity: clientData.activity?.id,
    inn: clientData.inn,
    interests: clientData.interests?.map((interest) => interest.id),
    note: clientData.note,
    brand: !!clientData.brand?.filter((item) => item?.trim()).length
      ? clientData.brand
      : undefined,
    contacts:
      clientData?.contacts.length > 2 &&
      (!clientData?.contacts[0].phone_number.trim() ||
        !clientData?.contacts[0].full_name.trim())
        ? []
        : clientData.contacts,
  };
  const order = {
    client: +selectedContact?.id! || applicationData.client,
    survey: applicationData.about?.map((item) => item.id),
    manager: applicationData.manager?.id || undefined,
    status: applicationData.status?.map((item) => item.id),
    jobs: editingApp
      ? checkedJobTypes.map((itemID) => {
          return {
            job: itemID,
            done: doneJobTypes.includes(itemID),
          };
        })
      : checkedJobTypes.map((item) => {
          return { job: item };
        }),
    price: applicationData.price || undefined,
    note: applicationData.note,
    started_at: applicationData.started_at
      ? moment(
          applicationData.started_at.split("-").length === 3
            ? convertDateFormat(applicationData.started_at)
            : applicationData.started_at
        ).format("YYYY-MM-DD")
      : undefined,
  };

  console.log(editingApp, fillPricingData, "loplop");
  const handleEdit = () => {
    if (!editingApp) return;

    const hasErrors = (contactCheck = true) => {
      const isContactValid = (contact: {
        phone_number: string;
        full_name: string;
        job_title: string;
        telegram: string;
      }) =>
        ((contact.phone_number && contact.phone_number.length >= 13) ||
          contact.telegram?.trim()) &&
        contact.full_name;

      const checkCommonConditions = () =>
        ("introduction" === fillPricingData?.step &&
          (!clientData.company_name ||
            !clientData.contacts.some(isContactValid))) ||
        ("choice_package" === fillPricingData?.step &&
          (!clientData.activity ||
            !clientData.brand?.length ||
            !clientData.company_name ||
            !clientData.contacts.some(isContactValid) ||
            !clientData.contacts.some((contact) => contact.job_title) ||
            !applicationData.about)) ||
        ("agreement" === fillPricingData?.step &&
          (!(clientData.inn && clientData.inn.length >= 9) ||
            !applicationData.status ||
            !checkedJobTypes.length ||
            !clientData.activity ||
            !clientData.brand?.length ||
            !clientData.company_name ||
            !clientData.contacts.some(isContactValid) ||
            !clientData.contacts.some((contact) => contact.job_title) ||
            !applicationData.about)) ||
        (["paying", "implementation"].includes(String(fillPricingData?.step)) &&
          (!applicationData.price ||
            !(clientData.inn && clientData.inn.length >= 9) ||
            !applicationData.status ||
            !checkedJobTypes.length ||
            !clientData.activity ||
            !clientData.brand?.length ||
            !clientData.company_name ||
            !clientData.contacts.some(isContactValid) ||
            !clientData.contacts.some((contact) => contact.job_title) ||
            !applicationData.about)) ||
        ("finish" === fillPricingData?.step &&
          (checkedJobTypes.length !== doneJobTypes.length ||
            !(clientData.inn && clientData.inn.length >= 9) ||
            !applicationData.status ||
            !checkedJobTypes.length ||
            !clientData.activity ||
            !clientData.brand?.length ||
            !clientData.company_name ||
            !clientData.contacts.some(isContactValid) ||
            !clientData.contacts.some((contact) => contact.job_title) ||
            !applicationData.about));
      return (
        (contactCheck && selectedContact && checkCommonConditions()) ||
        (!selectedContact && (nameExistAppear || checkCommonConditions()))
      );
    };

    const access = !hasErrors();

    if (hasErrors(true)) {
      setCheckErrors(true);
    }

    if (access) {
      dispatch(
        editLCApplicationThunk({
          orderId: editingApp.id,
          objects: changeObject(list.filter((item) => !item.id)),
          query,
          client: clientSend,
          order,
          clientId: editingApp.client.id,
          step: editingApp.step,
        })
      ).then(() => {
        dispatch(setAppsData([]));
        appTypes.forEach((item) => {
          dispatch(setAppsByStepPending(item.step));
          dispatch(getLCApplicationsByStep({ api: item.step }));
        });
      });
    }
  };

  console.log(selectedContact, "testsiysmomakrektingcrm");

  const handleSubmit = () => {
    let access = false;
    if (selectedContact) {
      if (
        !clientData.company_name ||
        !!!clientData.contacts.filter(
          (contact) =>
            ((contact.phone_number && contact.phone_number.length >= 13) ||
              contact.telegram?.trim()) &&
            contact.full_name
        ).length
      )
        setCheckErrors(true);
      else access = true;
    } else {
      if (
        nameExistAppear ||
        !clientData.company_name ||
        !!!clientData.contacts.filter(
          (contact) =>
            ((contact.phone_number && contact.phone_number.length >= 13) ||
              contact.telegram?.trim()) &&
            contact.full_name
        ).length
      )
        setCheckErrors(true);
      else access = true;
    }

    if (access) {
      console.log(order);

      dispatch(
        selectedContact
          ? createLCApplicationThunk({
              order,
              user: clientSend,
              objects: changeObject(),
              clientId: selectedContact.id,
            })
          : createLCApplicationWithClientThunk({
              user: {
                ...clientData,
                interests: clientData.interests?.map((item) => item.id) || null,
              },
              appData: { order, objects: changeObject() },
            })
      );
    }

    if (access) navigate(`${pathname}${UseReplace("search-client-name", "")}`);
  };

  const fillPricingSubmit = () => {
    console.log("HELLO GUYS--------------");
    const fillPriceData = {
      monitor: fillPricingData?.monitor,
      step: String(fillPricingData?.step),
      // @ts-ignore
      item: {
        ...fillPricingData?.item,
        ...order,
        client: applicationData?.client,
      } as LCAppsDataT,
    };
    // if (checkedJobTypes.length === doneJobTypes.length) {

    const hasErrors = (contactCheck = true) => {
      const isContactValid = (contact: {
        phone_number: string;
        full_name: string;
        job_title: string;
        telegram: string;
      }) =>
        ((contact.phone_number && contact.phone_number.length >= 13) ||
          contact.telegram?.trim()) &&
        contact.full_name;

      const checkCommonConditions = () =>
        ("introduction" === fillPricingData?.step &&
          (!clientData.company_name ||
            !clientData.contacts.some(isContactValid))) ||
        ("choice_package" === fillPricingData?.step &&
          (!clientData.activity ||
            !clientData.brand?.length ||
            !clientData.company_name ||
            !clientData.contacts.some(isContactValid) ||
            !clientData.contacts.some((contact) => contact.job_title) ||
            !applicationData.about)) ||
        ("agreement" === fillPricingData?.step &&
          (!(clientData.inn && clientData.inn.length >= 9) ||
            !applicationData.status ||
            !checkedJobTypes.length ||
            !clientData.activity ||
            !clientData.brand?.length ||
            !clientData.company_name ||
            !clientData.contacts.some(isContactValid) ||
            !clientData.contacts.some((contact) => contact.job_title) ||
            !applicationData.about)) ||
        (["paying", "implementation"].includes(String(fillPricingData?.step)) &&
          (!applicationData.price ||
            !(clientData.inn && clientData.inn.length >= 9) ||
            !applicationData.status ||
            !checkedJobTypes.length ||
            !clientData.activity ||
            !clientData.brand?.length ||
            !clientData.company_name ||
            !clientData.contacts.some(isContactValid) ||
            !clientData.contacts.some((contact) => contact.job_title) ||
            !applicationData.about)) ||
        ("finish" === fillPricingData?.step &&
          (checkedJobTypes.length !== doneJobTypes.length ||
            !(clientData.inn && clientData.inn.length >= 9) ||
            !applicationData.status ||
            !checkedJobTypes.length ||
            !clientData.activity ||
            !clientData.brand?.length ||
            !clientData.company_name ||
            !clientData.contacts.some(isContactValid) ||
            !clientData.contacts.some((contact) => contact.job_title) ||
            !applicationData.about));

      return (
        (contactCheck && selectedContact && checkCommonConditions()) ||
        (!selectedContact && (nameExistAppear || checkCommonConditions()))
      );
    };

    const access = !hasErrors();

    if (hasErrors(true)) {
      setCheckErrors(true);
    }
    console.log(access, "accesslogcheck");

    if (access) {
      dispatch(
        editLCApplicationThunk({
          orderId: editingApp?.id,
          // objects: changeObject(list.filter((item) => !item.id)),
          query,
          client: clientSend,
          order,
          clientId: editingApp?.client.id,
          step: editingApp?.step,
        })
      ).then(() => {
        onDrop!(fillPriceData!);
      });
    }

    // }
  };

  useEffect(() => {
    if (query.get("search-client-name")) dispatch(getLCContact(query));
    // if (!query.get("search-client-name")) setSelectedContact(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get("search-client-name")]);
  // useEffect(() => {
  //   if (query.get("search-recommended-name")) dispatch(getLCContact(query));
  //   if (!query.get("search-recommended-name")) setSelectedContact(null);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, query.get("search-recommended-name")]);

  useEffect(() => {
    console.log(editingApp, clientData, "editingapp");

    if (editingApp) {
      const {
        client,
        survey,
        manager: appManager,
        status,
        note: noteApp,
        price,
        started_at,
        jobs,
      } = editingApp;
      const {
        activity,
        company_name,
        interests,
        contacts,
        manager,
        inn,
        note,
        brand,
      } = client;
      setCheckedJobItems(jobs.map((item) => item.job_type.id));
      setDoneJobItems(
        jobs.filter((job) => job.done).map((item) => item.job_type.id)
      );
      setManager(`${appManager?.first_name} ${appManager?.last_name}`);
      setApplicationData({
        client: +String(client?.id),
        about: survey,
        status,
        manager: appManager,
        note: noteApp,
        price,
        started_at,
      });
      setClientData({
        activity,
        company_name,
        inn,
        note,
        manager,
        interests,
        brand,
        contacts,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingApp]);
  const handleCopy = () => {
    dispatch(setCopiedApp(editingApp));
    dispatch(openSnackbar({ status: "success", message: "Copied" }));
  };
  const handlePaste = () => {
    if (copiedApp) {
      const {
        client,
        survey,
        manager: appManager,
        status,
        note: noteApp,
        brand,
        price,
        started_at,
      } = copiedApp;
      const { activity, company_name, inn, contacts, manager } = client;

      setManager(`${appManager?.first_name} ${appManager?.last_name}`);
      setApplicationData({
        client: +String(client.id),
        about: survey,
        note: noteApp,
        manager: appManager,
        status,
        price,
        started_at,
      });
      setClientData({
        ...clientData,
        activity,
        company_name,
        inn,
        brand,
        manager,
        contacts,
      });
    }
  };
  console.log(applicationData);

  useEffect(() => {
    if (isFillPricing) setCheckErrors(true);
  }, [isFillPricing]);
  // useEffect(() => {
  //   console.log(clientData);

  //   const aboutValue =
  //     typeof clientData.name_type === "string"
  //       ? about.find((item) => item.name === "Работали ранее")
  //       : null;

  //   !editingApp &&
  //     setApplicationData({
  //       ...applicationData,
  //       about: Data["about"].find(
  //         (item) =>
  //           item.id === Number(aboutValue?.id || applicationData.about?.id)
  //       )!,
  //     });
  // }, [clientData]);
  console.log(isFillPricing, "datadigiabout");

  const [isExpiredApplication, setIsExpiredApplication] = useState(false);
  // useEffect(() => {
  //   setIsExpiredApplication(clientData.is_expired && !editingApp?.deadline);
  //   console.log(clientData.is_expired, clientData.deadline);
  // }, [clientData.is_expired, clientData.deadline]);
  // console.log(listItem, list, "saloao,dew");
  const Error = styled.div`
    color: red;
    margin-top: 5px;
    font-size: 16px;
  `;
  const formatter = new Intl.NumberFormat("fr-FR");
  return (
    <Box borderRadius="10px" overflow="auto" width="70vw" height="95vh">
      <Stack bgcolor="#FCFCFC" p="20px 45px" gap="31px" height="auto">
        <>
          <FlexCenter gap="20px" color="var(--primary)">
            <Text
              c="#828282"
              fs="24px"
              fw="700"
              style={{ textAlign: "center" }}
            >
              {editingApp
                ? editingApp?.step === "application" ||
                  editingApp?.step === "cancelled"
                  ? "Заявка"
                  : "Проект"
                : "Создать заявку"}
            </Text>
            {editingApp && (
              <Tooltip title="Copy">
                <IconButton color="inherit" onClick={handleCopy}>
                  <ContentCopyOutlined />
                </IconButton>
              </Tooltip>
            )}
            {copiedApp && !editingApp && (
              <Tooltip title="Paste copied data">
                <IconButton color="inherit" onClick={handlePaste}>
                  <ContentPasteOutlined />
                </IconButton>
              </Tooltip>
            )}
          </FlexCenter>
          {editingApp && (
            <Text
              c="var(--primary)"
              fs="18px"
              fw="700"
              style={{ textAlign: "center" }}
            >
              {manager}
            </Text>
          )}
          <Stack
            width="100%"
            direction="row"
            gap="37px"
            justifyContent="space-around"
          >
            <Stack style={editingApp ? { width: "40%" } : {}}>
              <ClientCard
                checkErrors={checkErrors}
                clientData={clientData}
                editingApp={editingApp}
                nameExistAppear={nameExistAppear}
                setRemovedContacts={setRemovedContacts}
                onChange={onChange}
                onChangeSelect={onChangeSelect}
                fillPricingData={fillPricingData}
                onChangeSelectAutocomplete={onChangeSelectAutocomplete}
                selectedContact={selectedContact}
                cannotChange={
                  profile.role === "crm_manager"
                    ? false
                    : editingApp?.step === "finish" ||
                      editingApp?.step === "cancelled"
                }
              />
            </Stack>
            {/* {!editingApp && (
              <SearchClientCard
                editingApp={editingApp}
                selectContact={selectContact}
                selectedContact={selectedContact}
                setCheckErrors={setCheckErrors}
                setClientData={setClientData}
              />
            )} */}
            {(editingApp &&
              contacts.results?.length > 0 &&
              editingApp?.client?.company_name !== clientData.company_name) ||
            !editingApp ? (
              <SearchClientCard
                editingApp={editingApp}
                selectContact={selectContact}
                selectedContact={selectedContact}
                setCheckErrors={setCheckErrors}
                setClientData={setClientData}
              />
            ) : (
              <FlexCenter
                pt={{ xs: "20px", d400: "30px", d800: "0" }}
                width="60%"
                borderRadius="9px"
                boxShadow=" 0px 4px 6px rgba(0,0,0,0.1)"
                bgcolor={{ xs: "none", d800: "var(--primary)" }}
                sx={{
                  img: {
                    width: { xs: "70%", d800: "100%" },
                  },
                }}
              >
                <img src={img} alt="" />
              </FlexCenter>
              // <SearchClientCard
              //   editingApp={editingApp}
              //   selectContact={selectContact}
              //   selectedContact={selectedContact}
              //   setCheckErrors={setCheckErrors}
              //   setClientData={setClientData}
              // />
              // <SearchClientCard
              //   editingApp={editingApp}
              //   selectContact={selectContact}
              //   selectedContact={selectedContact}
              //   setCheckErrors={setCheckErrors}
              //   setClientData={setClientData}
              // />
            )}
          </Stack>
        </>

        <Card fullWidth>
          <CardBody padding="18px">
            <Text
              c="#282C2D"
              fs="24px"
              fw="700"
              style={{ textAlign: "center" }}
            >
              Заполните заявку
            </Text>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems={"flex-start"}
              gap="14px"
            >
              <InputWrapper>
                <InputTitle>Источник информации </InputTitle>
                <SearchInputWrapper>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    value={applicationData.about || []}
                    onChange={(event, newValue) => {
                      console.log(newValue, event, "dewqdcaskoowq");

                      setApplicationData({
                        ...applicationData,
                        about: newValue,
                      });
                    }}
                    className="recommendedInput"
                    id="controllable-states-demo"
                    options={about || []}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: "100%", padding: 0 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Выбирать" />
                    )}
                  />
                  {checkErrors &&
                    (!applicationData.about ||
                      applicationData.about.length === 0) &&
                    ((!!fillPricingData &&
                      fillPricingData?.step !== "introduction") ||
                      (!!editingApp &&
                        editingApp?.step !== "introduction")) && (
                      <Error>*{i18next.t("validations.fill")}</Error>
                    )}
                </SearchInputWrapper>
              </InputWrapper>
              <InputWrapper>
                <InputTitle>Выбор статуса клиента</InputTitle>
                <SearchInputWrapper>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    value={applicationData.status || []}
                    onChange={(event, newValue) => {
                      console.log(newValue, event, "dewqdcaskoowq");

                      setApplicationData({
                        ...applicationData,
                        status: newValue,
                      });
                    }}
                    className="recommendedInput"
                    id="controllable-states-demo"
                    options={lsStatuses || []}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: "100%", padding: 0 }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Выбирать" />
                    )}
                  />
                  {checkErrors &&
                    (!applicationData.status ||
                      applicationData.status.length === 0) &&
                    ((!!fillPricingData &&
                      !["introduction", "choice_package"].includes(
                        fillPricingData?.step
                      )) ||
                      (!!editingApp &&
                        !["introduction", "choice_package"].includes(
                          String(editingApp?.step)
                        ))) && <Error>* {i18next.t("validations.fill")}</Error>}
                </SearchInputWrapper>
              </InputWrapper>
              {editingApp && profile.role === "director" && (
                <InputWrapper>
                  <InputTitle>Oтветственный менеджер по заявке</InputTitle>
                  <CustomAutocomplete
                    inputValue={inputValue}
                    setInputValue={handleInputChange}
                    selectedOption={applicationData.manager}
                    height="40px"
                    setSelectedOption={(e) => {
                      if (onChangeSelectApplication) {
                        onChangeSelectApplication(
                          e,
                          "manager",
                          employees.results?.map((value) => {
                            return {
                              id: Number(value?.id),
                              name: value?.first_name + " " + value?.last_name,
                            };
                          })
                        );
                      } else return undefined;
                    }}
                    options={autoCompleteOptions}
                  />
                </InputWrapper>
              )}
              <InputWrapper>
                <InputTitle>Сумма оплаты </InputTitle>
                <SearchInputWrapper>
                  <CustomInput
                    name="price"
                    showError={
                      checkErrors &&
                      !applicationData.price &&
                      ((!!fillPricingData &&
                        ["paying", "implementation"].includes(
                          String(fillPricingData?.step)
                        )) ||
                        (!!editingApp &&
                          ["paying", "implementation"].includes(
                            String(fillPricingData?.step)
                          )))
                    }
                    value={
                      applicationData?.price && applicationData?.price !== "0"
                        ? formatter.format(Number(applicationData?.price))
                        : ""
                    }
                    // onChange={onChange}
                    onChange={(e) => {
                      setApplicationData({
                        ...applicationData,
                        price: String(String(e).replaceAll(/\D/g, "")),
                      });
                    }}
                    type="text"
                    height="34px"
                    fullWidth
                    p="10px"
                    pl="10px"
                  />
                </SearchInputWrapper>
              </InputWrapper>

              <InputWrapper>
                <InputTitle>Дата создания договора </InputTitle>
                <SearchInputWrapper>
                  <LocalizationProvider
                    adapterLocale="en-gb"
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      title="Дата"
                      keyPicker="date_from"
                      value={
                        applicationData.started_at
                          ? applicationData.started_at.split("-").length === 3
                            ? dayjs(
                                convertDateFormat(applicationData.started_at)
                              )
                            : dayjs(applicationData.started_at)
                          : null
                      }
                      onChangePicker={(e) =>
                        setApplicationData({
                          ...applicationData,
                          started_at: String(e),
                        })
                      }
                    />
                  </LocalizationProvider>
                  {/* {checkErrors && !applicationData.started_at && (
                    <Error>* {i18next.t("validations.fill")}</Error>
                  )} */}
                </SearchInputWrapper>
              </InputWrapper>
              <InputWrapper>
                <InputTitle>{t("common.note")}</InputTitle>
                <SearchInputWrapper>
                  <CustomInputTextfield
                    fs="14px"
                    name="note"
                    errorFs="12px"
                    value={applicationData.note || ""}
                    onChange={(e) => {
                      setApplicationData({
                        ...applicationData,
                        note: String(e),
                      });
                    }}
                    fullWidth
                    type="text"
                    height="50px"
                    pl="10px"
                  />
                </SearchInputWrapper>
              </InputWrapper>
            </Stack>
            <Stack>
              <FlexBetween alignItems="center">
                <Text
                  c="#282C2D"
                  fs="24px"
                  fw="700"
                  style={{ marginTop: "10px", textAlign: "left" }}
                >
                  Наши обязательства
                </Text>
                {editingApp && (
                  <CustomButton
                    bgColor="#fff"
                    fs="18px"
                    value={
                      isEditJobItems ? (
                        <p>Save</p>
                      ) : (
                        <img src={edit} alt="edit icon" />
                      )
                    }
                    width="fit-content"
                    padding="12px"
                    fw="600"
                    height="41px"
                    shadow
                    onClick={() => {
                      setIsEditJobItems(!isEditJobItems);
                    }}
                  />
                )}
              </FlexBetween>
              {((checkErrors &&
                checkedJobTypes.length === 0 &&
                ((!!fillPricingData &&
                  !["introduction", "choice_package"].includes(
                    fillPricingData?.step
                  )) ||
                  (!!editingApp &&
                    !["introduction", "choice_package"].includes(
                      String(editingApp?.step)
                    )))) ||
                (fillPricingData &&
                  checkedJobTypes.length !== doneJobTypes.length &&
                  ((!!fillPricingData && fillPricingData?.step === "finish") ||
                    (!!editingApp && editingApp?.step === "finish")))) && (
                <Error>*{i18next.t("validations.fill")}</Error>
              )}
              <FlexWrapper>
                {editingApp && !isEditJobItems ? (
                  <FormGroup
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {checkedJobTypes.map((itemID) => (
                      <FormControlLabel
                        key={itemID}
                        control={
                          <Checkbox
                            checked={doneJobTypes.includes(
                              ourCommitments.find((job) => job.id === itemID)
                                ?.id || -1
                            )}
                            disabled={editingApp.step !== "implementation"}
                            onChange={() => handleDoneCheckboxChange(itemID)}
                          />
                        }
                        label={
                          ourCommitments.find((job) => job.id === itemID)?.name
                        }
                      />
                    ))}
                  </FormGroup>
                ) : (
                  <FormGroup
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {ourCommitments.map((item) => (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            checked={checkedJobTypes.includes(item.id)}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        }
                        label={item.name}
                      />
                    ))}
                  </FormGroup>
                )}
              </FlexWrapper>
            </Stack>
          </CardBody>
        </Card>

        <Stack direction={"row"} gap="17px" justifyContent="center">
          <Text
            c="#6C6C6C"
            fs="20px"
            fw="700"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          >
            Отменить
          </Text>
          <CustomButton
            bgColor="var(--primary)"
            fs="20px"
            value={"Сохранить"}
            color="#fff"
            type="button"
            width="123px"
            height="32px"
            padding="0"
            fw="700"
            loading={actionsPending === "post-application"}
            disable={
              !(!cannotEditApp || profile.role === "crm_manager") ||
              !!editingAppComp ||
              actionsPending === "post-application" ||
              nameExistAppear
            }
            onClick={() =>
              isFillPricing
                ? fillPricingSubmit()
                : editingApp
                ? handleEdit()
                : handleSubmit()
            }
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddLCApplication;
